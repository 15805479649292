import { Site } from '@contracts/types/Site'

import { getFigmaText } from '@pure/libs/TextRepository'
import { formatText } from '@pure/libs/TextRepositoryHelper'

import Texts from '@pay/libs/Texts'

export const getFreeParkingDuration = (site: Site) => {
  const segment = site?.segments?.[0]
  const pricing = segment?.pricing?.parking
  const isAbsolute = pricing?.type === 'absolute'

  const hasFreeTime = isAbsolute
    ? pricing?.items?.map((item) => item?.freeDurationMinutes).some((freeDuration: any) => freeDuration > 0)
    : pricing?.freeDurationMinutes && pricing?.freeDurationMinutes > 0

  if (!hasFreeTime) return false

  if (isAbsolute) {
    return {
      header: getFigmaText(Texts.textPaymentsEnjoyFreeParking),
      subheader: getFigmaText(Texts.textPaymentsFreeParkingLimitations)
    }
  }

  const segmentCount = site?.segments?.length

  if (segmentCount === 1 || segmentCount === 2) {
    // TODO: Fix types so ts-ignore is not needed here
    // @ts-ignore
    const freeTimes = site.segments.map((s) => s?.pricing?.parking?.freeDurationMinutes)
    const [freeTimeFirstSegment, freeTimeSecondSegment] = freeTimes

    if (segmentCount === 1 || freeTimeFirstSegment === freeTimeSecondSegment) {
      const freeTime = freeTimeFirstSegment

      if (freeTime && freeTime <= 59) {
        return formatText(getFigmaText(Texts.textPaymentstextXminutesFreeParking), [freeTime], {
          splitCharacter: 'X'
        })
      }

      return formatText(getFigmaText(Texts.textPaymentstextXhoursFreeParking), [(freeTime / 60).toFixed(0)], {
        splitCharacter: 'X'
      })
    }
  }
}
