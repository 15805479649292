import {
  CloudFunctions,
  CreateStripCheckoutSessionResponse,
  CreateStripeCheckoutSessionRequest,
  GetActiveSessionPublicForPlateRequest,
  SendPermitFormRequest,
  SendReceiptRequest
} from '@contracts/types/CloudFunctionTypes'
import { Session } from '@contracts/types/Session'

import { callFunction } from '@web-js/libs/CloudFunctionsApiHandlerHelper'

import { functions } from './FirebaseOptions'

export const sendPermitForm = (data: SendPermitFormRequest) =>
  callFunction({ functions, cloudfunction: CloudFunctions.sendPermitForm, data }) as Promise<any>

export const sendExpiredReceipt = (data: SendReceiptRequest) =>
  callFunction({ functions, cloudfunction: CloudFunctions.sendExpiredReceipt, data }) as Promise<Session>

export const sendReceiptForSession = (data: SendReceiptRequest) =>
  callFunction({ functions, cloudfunction: CloudFunctions.sendReceiptForSession, data }) as Promise<Session>

export const createStripeCheckoutSession = (data: CreateStripeCheckoutSessionRequest) =>
  callFunction({
    functions,
    cloudfunction: CloudFunctions.createStripeCheckoutSession,
    data
  }) as Promise<CreateStripCheckoutSessionResponse>

export const createFortnoxCredentials = (data = {}) =>
  callFunction({ functions, cloudfunction: CloudFunctions.createFortnoxCredentials, data }) as Promise<any>

export const getActiveSessionForPlate = (data: GetActiveSessionPublicForPlateRequest): Promise<Session | undefined> =>
  callFunction({ functions, cloudfunction: CloudFunctions.getActiveSessionForPlate, data }) as Promise<
    Session | undefined
  >
